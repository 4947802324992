import './index.scss'
import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import AnimatedLetters from '../../AnimatedLetters'

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  // const sectionTitle = ['P', 'O', 'R', 'T', 'F', 'O', 'L', 'I', 'O']

  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="section-portfolio">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'PORTFOLIO'.split('')}
              idx={15}
            />
          </h1>
        </div>

        <div className="left-section">
          <p>
            An incoming software engineer who is passionate about software
            development, innovation and designing with experience in robotics,
            AI, human-computer interaction (HCI), cybersecurity and database
            management. Always ready to learn and unlearn because knowledge is
            endless.
          </p>
          <p>
            {' '}
            <br />{' '}
          </p>
          <div className="img-left">
            <img
              src="/portfolio/logos/web-encrypt-logo.png"
              srcSet="/portfolio/logos/web-encrypt-logo.png 1.5x"
              alt="web-encrypted-chat-app"
            ></img>
            <h2>
              Jemessaging{' '}
              <p>
                A web-based chatting app that allows users to send encrypted
                messages, with the educational purpose of teaching its users the
                importance of online security and privacy
              </p>
              <p>
                Techstack: React.js / Web Cryptography API / Socket.io /
                Netlify/ Heroku
              </p>
            </h2>
          </div>
          <p>
            {' '}
            <br />{' '}
          </p>
          <div className="img-right">
            <h2>
              Azure Chatbot
              <p>
                Used Microsoft Azure Bot to develop and improve the chatbot
                services that We Sparkle provided for a number of customers,
                such as ThePriceDynamic, MeetMinneapolis and Launch Minnesota.
              </p>
              <p>
                Techstack: Azure Bot Framework Composer / React / Azure / AI &
                Natural Language Processing
              </p>
            </h2>
            <img
              src="/portfolio/logos/we-sparkle-logo.png"
              srcSet="/portfolio/logos/we-sparkle-logo.png 1.5x"
              alt="microsoft-azure"
            ></img>
          </div>
          <p>
            {' '}
            <br />{' '}
          </p>
          <div className="img-left">
            <img
              src="/portfolio/logos/blockly-logo.png"
              srcSet="/portfolio/logos/blockly-logo.png 1.5x"
              alt="blockly"
            ></img>
            <h2>
              Google Blockly
              <p>
                Improved the user-interface of HCI Project led by Prof. Milne to
                incorporate with screen readers to make Blockly more accessible
                to people, especially children, who are blind or visually
                impaired
              </p>
              <p>
                Techstack: Google Blockly API / JavaScript / HTML / CSS / ARIA
              </p>
            </h2>
          </div>
          <p>
            {' '}
            <br />{' '}
          </p>
          <div className="img-right">
            <h2>
              Kuri Social Robot Simulation
              <p>
                A social robot simulation that can do facial recognition, speech
                recognition and response in order to have interaction with
                humans
              </p>
              <p>
                Techstack: Python / Google Cloud Speech API / Google’s
                Text-To-Speech / Python’s Face-Recognition Library P\
              </p>
            </h2>
            <img
              src="/portfolio/logos/kuri-logo.png"
              srcSet="/portfolio/logos/kuri-logo.png 1.5x"
              alt="kuri"
            ></img>
          </div>
          <p>
            {' '}
            <br />{' '}
          </p>
          <div className="img-left">
            <img
              src="/portfolio/logos/clink-logo.png"
              srcSet="/portfolio/logos/clink-logo.png 1.5x"
              alt="clink"
            ></img>
            <h2>
              Clink: Food Dating App
              <p>
                Collaborated with 3 teammates to build a cross-platform mobile
                app that allows people to make connections based on their shared
                interests in food
              </p>
              <p>Techstack: Flutter / Dart / Google Firebase</p>
            </h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default Portfolio
