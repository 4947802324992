// import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
// import Loader from 'react-loaders'
// import AnimatedLetters from '../AnimatedLetters'
import Intro from './Intro'
// import LogoTitle from '../../assets/images/logo-s.png'
// import Logo from './Logo'
import './index.scss'
import Portfolio from './Portfolio'
import Work from './Work'
import Contact from './Contact'


const Home = () => {
  return (
    <>
      <div className='home'>
        <Intro />
        <Portfolio />
        <Work />
        <Contact />
      </div>

      {/* <Loader type="pacman" /> */}
    </>
  )
}

export default Home
