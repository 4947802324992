import AnimatedLetters from '../../AnimatedLetters'
import './index.scss'
import { useEffect, useState } from 'react'

const Work = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="section-work">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'WORK'.split('')}
              idx={15}
            />
          </h1>
          {/* <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'    O'.split('')}
              idx={15}
            />
          </h1>
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'        R'.split('')}
              idx={15}
            />
          </h1>
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'            K'.split('')}
              idx={15}
            />
          </h1> */}
        </div>
        <div className="resume-module-1">
          <h2 className="resume-heading">
            Software Engineer Intern @We Sparkle
          </h2>
          <p className="resume-description">
            Use Microsoft Azure Bot to develop and improve the chatbot services
            that We Sparkle provided for a number of customers, such as
            ThePriceDynamic, MeetMinneapolis and Launch Minnesota.
          </p>
          <p className="resume-description">
            Techstack: Azure Bot Framework Composer / React / Azure / AI &
            Natural Language Processing
          </p>
        </div>
        <div className="resume-module-2">
          <h2 className="resume-heading">HCI Researcher @Macalester College</h2>
          <p className="resume-description">
            Improved the user-interface of HCI Project led by Prof. Milne to
            incorporate with screen readers to make Blockly more accessible to
            people, especially children, who are blind or visually impaired
          </p>
          <p className="resume-description">
            Techstack: Google Blockly API / JavaScript / HTML / CSS / ARIA
          </p>
        </div>
      </div>
    </>
  )
}

export default Work
