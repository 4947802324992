import './index.scss'
// import Sidebar from '../Sidebar'
import { Outlet } from 'react-router-dom'
// import Home from '../Home'
// import About from '../About'

const Frame = () => {
  return (
    <div className="App">
      {/* <Sidebar /> */}
      <div className="page">
        <Outlet />
        {/* <About /> */}
      </div>
    </div>
  )
}

export default Frame
