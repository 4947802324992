import AnimatedLetters from '../../AnimatedLetters'
import './index.scss'
import { useEffect, useState } from 'react'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="section-contact">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'CONTACT'.split('')}
              idx={15}
            />
          </h1>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="contact-card">
          <div className="top-block"></div>
          <div className="bottom-block">
            <h2>
              <br />
              <br />I am always open to new opportunities to grow or help people
              out. If you want to get in touch for whatever reason, feel free to
              do so. You can contact me through cyang4@macalester.edu or{' '}
              <a href="https://www.linkedin.com/in/james-yang-chialunyo/">
                LinkedIn
              </a>
              .
            </h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
